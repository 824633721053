<template>
  <div class="page-login">
    <form class="form-login" autocomplete="off" @submit.prevent="login">
			<p class="head">Здравствуйте, <br>введите логин и пароль <br>для входа в систему:</p>
			<div class="error" v-if="error">
				<div v-html="error"></div>
				<svg class="close" width="8" height="8" viewBox="0 0 8 8" fill="none" @click="error = ''"><g clip-path="url(#clip0)"><path d="M7.90469 0.0953186C7.77813 -0.0312439 7.57344 -0.0312439 7.44844 0.0953186L0.0953186 7.44844C-0.0312439 7.57501 -0.0312439 7.77969 0.0953186 7.90469C0.157819 7.96719 0.240631 8.00001 0.323444 8.00001C0.406256 8.00001 0.489069 7.96876 0.551569 7.90469L7.90469 0.551569C8.03126 0.425006 8.03126 0.220319 7.90469 0.0953186Z" fill="#392D2B"/><path d="M7.90469 7.44844L0.551569 0.0953186C0.425006 -0.0312439 0.220319 -0.0312439 0.0953186 0.0953186C-0.0312439 0.220319 -0.0312439 0.425006 0.0953186 0.551569L7.44844 7.90469C7.51094 7.96719 7.59376 8.00001 7.67657 8.00001C7.75938 8.00001 7.84219 7.96876 7.90469 7.90469C8.03126 7.77969 8.03126 7.57501 7.90469 7.44844Z" fill="#392D2B"/></g><defs><clipPath id="clip0"><rect width="8" height="8" fill="white"/></clipPath></defs></svg>
			</div>
      <input type="text" name="login" placeholder="Введите логин*" v-model="form.login">
      <input type="text" name="password" placeholder="Введите пароль*" v-model="form.password">
			<p class="description">После 3-х неудачных попыток вход в ЛК будет заблокирован. Для восстановления доступа обратитесь к администратору.</p>
      <button class="button">Войти</button>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
    	form: {
				login: '',
				password: ''
			},
			error: ''
    }
	},
	validations: {
		form: {
			login: {
				required
			},
			password: {
				required
			}
		}
	},
  methods: {
		login () {
			this.error = '';
			this.$v.$touch()
			if (this.$v.$invalid) {
				if(this.$v.form.login.$error && this.$v.form.password.$error) {
					this.error = '<p>Поле «Логин» обязательно для заполнения.</p><p>Поле «Пароль» обязательно для заполнения.</p>';
				} else if(this.$v.form.login.$error) {
					this.error = '<p>Поле «Логин» обязательно для заполнения.</p>';
				} else if(this.$v.form.password.$error) {
					this.error = '<p>Поле «Пароль» обязательно для заполнения.</p>';
				}
				return false;
			}
			this.$store.dispatch('login', this.form)
				.then(() => {
					this.$router.push({ name: 'Home' })
				})
				.catch(error => {
					this.error = error.response.data.message
				})
		}
  }
}
</script>

<style lang="sass">
.page-login
	min-height: 100vh
	display: flex
	align-items: center
	justify-content: center
	.form-login
		max-width: 360px
		padding: 25px 30px 30px 30px
		background: #fff
		border-radius: 5px
		.head
			font-weight: 800
			font-size: 24px
			text-align: center
			margin-bottom: 25px
		.error
			display: flex
			align-items: center
			justify-content: space-between
			background: rgba(245,196,192,0.5)
			padding: 12px 15px
			border-radius: 5px
			margin-bottom: 30px
			font-size: 13px
			box-shadow: 0px 0px 0px 5px rgba(245,196,192,0.2)
			p
				margin-bottom: 7px
				&:last-child
					margin: 0
			.close
				flex-shrink: 0
				margin-left: 15px
				cursor: pointer
		input
			width: 100%
			height: 40px
			font-size: 14px
			padding: 0 10px
			margin-bottom: 19px
			border-radius: 5px
			border: 1px solid rgba(57, 45, 43, 0.3)
			transition: 0.3s
			&:focus
				border-color: rgba(57, 45, 43, 0.5)
		.description
			font-size: 12px
		.button
			width: 100%
			height: 50px
			border-radius: 5px
			background: #04b67d
			color: #fff
			text-transform: uppercase
			font-weight: 700
			font-size: 16px
			border: none
			cursor: pointer
			transition: 0.3s
			&:hover
				background: #0abf85
</style>